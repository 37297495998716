exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".packages-webviz-core-src-panels-PanelList-index-module-2qfvk--container{padding:16px}.packages-webviz-core-src-panels-PanelList-index-module-c8r7a--item{cursor:-webkit-grab;cursor:grab}", "", {"version":3,"sources":["/app/packages/webviz-core/src/panels/PanelList/index.module.scss"],"names":[],"mappings":"AAAA,yEACE,YAAA,CAGF,oEACE,oBAAA,WAAA,CAAA","file":"index.module.scss","sourcesContent":[".container {\n  padding: 16px;\n}\n\n.item {\n  cursor: grab;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "packages-webviz-core-src-panels-PanelList-index-module-2qfvk--container",
	"item": "packages-webviz-core-src-panels-PanelList-index-module-c8r7a--item"
};